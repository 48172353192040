<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        :basic-data="basicData"
        title="Fix Client List"
        icon="mdi-account-group"
        addButtonText="Fix Client"
        stateendpoint="fixClient.fixClients"
        :actions="actions"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import FixClientPopup from "../components/popups/FixClientPopup";

export default {
  name: 'FixClients',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Fix Clients | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage, FixClientPopup},
  data() {
    return {
      headers: [
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Country',
          value: 'country'
        },
        {
          text: 'Price',
          value: 'price',
          align: 'right'
        },
        {
          text: 'Link Price',
          value: 'linkPrice',
          align: 'right'
        },
        {
          text: 'Other Link Price',
          value: 'adultPrice',
          align: 'right'
        },
        {
          text: 'Percentage of Discount(%)',
          value: 'discount'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'email'},
        {value: 'price'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadFixClients',
        create: 'storeFixClient',
        update: 'updateFixClient',
        remove: 'removeFixClient',
        destroy: 'destroyFixClient'
      },
      basicData: {
        price: 0,
        linkPrice: 0,
        adultPrice: 0,
        type: 0,
        discount: 0
      }
    }
  },
  computed: {
    popupComponent() {
      return FixClientPopup;
    }
  }
}
</script>
